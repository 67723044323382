import React from "react";
import CharlesMeta from "components/common/meta/CharlesMeta";
import StructuredWebPage from "components/common/meta/StructuredWebPage";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Layout } from "components/common";
import Section from "components/common/Section";
import { format } from "date-fns";
import "./styles.sass";
import { AirtableImages } from "components/common/utils/AirtableImage";
import useAirtableArticles from "hooks/useAirtableArticles";

const title = "Ils parlent de nous"
const description = "Charles.co est le premier service de télé-consultation et de livraison de traitement au service de la santé des hommes. La presse en parle."

const Press = () => {
  return (
    <Layout showBars showLinksInFooter={false}>
      <CharlesMeta
        data={{
          title: 'La presse parle de nous : nos références | Charles.co',
          description: description
        }}
      />
      <StructuredWebPage
        title={title}
        description={description}
      />
      <div>
        <Section className="pt-2 pr-1 pl-1 pt-sm-5 pr-sm-5 pl-sm-5 pb-0 press-first-part">
          <Container>
            <h1 className="mb-4 section-title text-center press-main-title">
              {title}
            </h1>
            <Row>
              <Col xs={12} md={{order: 2}}>
                <h2 className="mb-4 kit-media-text text-center">
                <span>
                  Vous pouvez nous contacter à l'adresse:
                  <span className="press-mail">
                    <a href="mailto:presse@charles.co"> presse@charles.co</a>
                  </span>
                </span>
                  <br/>
                  <span>
                  Téléchargez le Kit Média en cliquant{" "}
                    <a
                      href="https://drive.google.com/drive/folders/1RVnYjBAexLUbE6QvUvymmteLYSZIShPZ?usp=sharing"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                    ici
                  </a>
                </span>
                  <br/>
                </h2>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="lc-white pt-0 pr-1 pl-1 pt-sm-5 pr-sm-5 pl-sm-5 pb-0">
          <Container className="d-flex text-center justify-content-center">
            <Row className="justify-content-center mt-5 text-left">
              <Col md={12}>
                {useAirtableArticles().map(
                  ({data: {external_url, image, published_at, title}, id}) => (
                    <a
                      href={external_url}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      className="press-list-single-article"
                      key={`article-${id}`}
                    >
                      <div className="press-articles-div">
                        <AirtableImages
                          images={image}
                          className="img-fluid press-image"
                        />
                        <div className="press-articles-text">
                          <h3 className="text-left press-title">
                            {title}
                          </h3>
                          <span className="small press-date">
                          {format(new Date(published_at), "dd-MM-yyyy")}
                        </span>
                        </div>
                      </div>
                    </a>
                  )
                )}
              </Col>
            </Row>
          </Container>
        </Section>
      </div>
    </Layout>
  )
};

export default Press;
