import { useStaticQuery, graphql } from "gatsby"
import T from "types/index";

export default () : T.Charles.ArticleRecord[] => {
  const { allAirtableArticles: { nodes } } = useStaticQuery(graphql`
    query StaticArticlesQuery {
      allAirtableArticles(
        sort: { fields: data___published_at, order: DESC }
        filter: { data: { published: { eq: true } } }
      ) {
        nodes {
          data {
            title
            published_at
            image { ...AirtableImagesConstrainedFields }
            external_url
          }
        }
      }
    }
  `);
  return nodes
}
